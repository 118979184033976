import Siema from "siema";

document.addEventListener("DOMContentLoaded", event => {
	var toggleInfo = document.querySelector(".toggle-info");

	toggleInfo.addEventListener("click", event => {
		document.querySelector(".info").classList.toggle("open");
	});

	var headerReveals = document.querySelectorAll(".header-reveal");

	headerReveals.forEach(headerReveal => {
		hover = headerReveal.querySelector("span");
		content = headerReveal.querySelector("div");

		hover.addEventListener("mouseenter", e => {
			content = headerReveal.querySelector("div");
			content.style.opacity = 1;
			content.style.pointerEvents = "all";
		});

		hover.addEventListener("mouseleave", e => {
			content = headerReveal.querySelector("div");
			content.style.opacity = 0;
			content.style.pointerEvents = "none";
		});

		content.addEventListener("mouseover", e => {
			content = headerReveal.querySelector("div");
			content.style.opacity = 1;
			content.style.pointerEvents = "all";
		});

		content.addEventListener("mouseleave", e => {
			content = headerReveal.querySelector("div");
			content.style.opacity = 0;
			content.style.pointerEvents = "none";
		});
	});

	var projects = document.querySelectorAll(".project-images");

	projects.forEach(project => {
		var siema = project.querySelector(".image-carousel");
		if (siema) {
			var mySiema = new Siema({
				selector: siema,
				loop: true
			});
			var prev = project.querySelector(".prev");
			var next = project.querySelector(".next");

			prev.addEventListener("click", () => mySiema.prev());
			next.addEventListener("click", () => mySiema.next());
		}
	});
});
